<script>
  import { get, call } from 'vuex-pathify'
  import { ApiError } from '@/api'

  export default {
    data() {
      return {
        formRef: null,
        firstName: this.$store.get('account/firstName'),
        lastName: this.$store.get('account/lastName'),
        phoneNumber: this.$store.get('account/phoneNumber'),
      }
    },
    computed: {
      ...get('account', ['username']),
    },
    methods: {
      ...call('account', ['updateAccountInfo']),
      async submit() {
        try {
          await this.formRef.process(
            this.updateAccountInfo({
              firstName: this.firstName,
              lastName: this.lastName,
              phoneNumber: this.phoneNumber,
            })
          )
          this.$navigate({ name: 'AccountProfileSettings' })
        } catch (error) {
          if (error instanceof ApiError) {
            return
          }
          return Promise.reject(error)
        }
      },
    },
  }
</script>

<template>
  <section>
    <BaseForm
      v-slot="{ inputsEnabled, submitEnabled, errorMessage }"
      v-ref="formRef"
      spinner
      @submit="submit"
    >
      <BaseHeading size="h5" tag="h2" class="mb-6">Edit Account Info</BaseHeading>

      <BasePanelWarning v-if="errorMessage" class="mb-6">
        {{ errorMessage }}
      </BasePanelWarning>

      <BaseStack>
        <BaseInput
          v-model="firstName"
          type="text"
          name="firstName"
          :disabled="!inputsEnabled"
          required
        >
          First Name
        </BaseInput>
        <BaseInput
          v-model="lastName"
          type="text"
          name="lastName"
          :disabled="!inputsEnabled"
          required
        >
          Last Name
        </BaseInput>
        <BaseInput type="email" :value="username" disabled>Email</BaseInput>
        <BaseInput v-model="phoneNumber" type="tel" :disabled="!inputsEnabled">
          Phone Number
        </BaseInput>

        <div class="text-xs text-gray pt-2 italic">
          By signing up via text, you agree to receive recurring automated promotional and
          personalized marketing text messages (e.g. cart reminders) from Alltrue or its providers
          (may be via automated telephonic dialing system) at the cell number used when signing up.
          Consent is not a condition of any purchase. Reply HELP for help and STOP to cancel. Msg
          frequency varies. Msg &amp; data rates may apply. View full
          <BaseLinkStyled :to="{ name: 'PageTermsOfUse' }">Terms</BaseLinkStyled> &amp;
          <BaseLinkStyled :to="{ name: 'PagePrivacy' }">Privacy Policies</BaseLinkStyled>.
        </div>
      </BaseStack>

      <BaseButton type="submit" :disabled="!submitEnabled" class="mt-6"> Save </BaseButton>
    </BaseForm>

    <div class="mt-6 -mb-2 flex justify-center">
      <BaseLinkStyled :to="{ name: 'AccountProfileSettings' }" color="gray" font-size="xs">
        Cancel
      </BaseLinkStyled>
    </div>
  </section>
</template>
